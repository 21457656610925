<template>
  <li class="card" @click="$router.push({ name: 'game', params: { id: game.id } })">
    <!-- <img :src="require('@/assets/images/running-card/card7.jpg')" alt="" /> -->
    <img :src="imageLink(game.image)" alt="" />
    <div class="card-info">
      <h3>
        {{ game.name }}
      </h3>
      <p class="card-description Q-font">{{ game.description }}</p>
      <!-- <router-link
        class="game-link lt-hover"
        :to="{ name: 'game', params: { id: game.id } }"
        >View Game <i class="bx bx-link-alt bx-burst"></i
      ></router-link> -->
    </div>
  </li>
</template>

<script>
export default {
  props: ["game"],
  methods: {
    imageLink(image) {
      return `${process.env.VUE_APP_ROOT_STORAGE}/${image}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/_mixins.scss";
.card {
  flex: 0 0 32%;
  margin: 0 auto;
  margin-bottom: 1.5em;
  border-radius: 0.5em;
  box-shadow: 0.05em 0.1em 0.3em -0.03em rgba(0, 0, 0, 0.45);
  overflow: hidden;
  color: white;
  min-height: fit-content;
  background: linear-gradient(to top, black 25%, #7ec7fad1);
  border: 2px solid var(--color7);
  cursor: pointer;
  &:hover {
    img {
      transform: scale(1.3);
      filter: hue-rotate(360deg);
    }
  }
  @include md {
    flex: 0 0 47%;
    font-size: 12px;
  }
  @include sm {
    flex: 0 0 90%;
    // margin: 5px;
  }
  img {
    width: 100%;
    object-fit: contain;
    aspect-ratio: 4/3;
    // mask-image: linear-gradient(to bottom, rgb(255, 0, 0), transparent);
    mix-blend-mode: overlay;
    transition: all 1s cubic-bezier(0, 0.88, 0.26, 0.58);
  }
  .card-info {
    padding: 0.625em;
    display: flex;
    flex-direction: column;
    color: rgb(0, 149, 255);
    h3 {
      font-size: 0.9975em;
      margin-bottom: 0.3125em;
    }
    .card-description {
      font-size: 0.725em;
      color: rgb(190, 190, 190);
      @include sm {
        font-size: 9px;
      }
    }
    .game-link {
      color: white;
      background: var(--home-bg-lighter);
      margin-top: auto;
      border-radius: 0.3125em;
      padding: 0.3125em 0;
      font-size: 0.75em;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.5s ease;
      i {
        margin-left: 5px;
      }
    }
  }
}
</style>
