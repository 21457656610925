<template>
  <div class="indexes container">
    <div class="index-table">
      <div class="index-head">
        <h2 class="index-text">Games List</h2>
      </div>
      <div class="sections" v-if="$route.name === 'games'">
        <GamesBannerSlider></GamesBannerSlider>
        <AllGames />
      </div>
      <!-- <RunningTournaments v-if="trTabs[1].isActive" />
      <JoinedTournaments v-if="trTabs[2].isActive" /> -->
      <router-view v-else></router-view>
    </div>
  </div>
</template>

<script>
import AllGames from "@/components/panelSections/games/AllGames.vue";
import GamesBannerSlider from "@/components/panelSections/games/GamesBannerSlider.vue";
export default {
  components: { GamesBannerSlider, AllGames },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/compStyles/_indexes.scss";
.index-table {
  // background-image: radial-gradient(
  //   circle at center,
  //   orange 20%,
  //   rgb(0, 0, 0) 70%
  // ) !important;
}
</style>
