<template>
  <NoResult v-if="!games || (!games.length && !loading)" class="fit-height"></NoResult>
  <div v-else>
    <BaseLoading v-if="loading" class="fit-height"></BaseLoading>
    <div class="games-container" v-else>
      <ul class="games">
        <GameCard v-for="(game, i) in games" :key="i" :game="game"></GameCard>
      </ul>
      <Pagination
        :total="total"
        :page="page"
        :lastPage="lastPage"
        :loading="loading"
        @nextPage="changePage"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import GameCard from "./GameCard.vue";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      games: [],
      page: 1,
      total: 0,
      lastPage: 1,
      loading: false,
    };
  },
  components: {
    GameCard,
  },
  created() {
    this.fetchGames();
  },
  methods: {
    ...mapActions(["getGames"]),
    fetchGames() {
      const params = new URLSearchParams({ page: this.page, per_page: 8 });
      this.loading = true;
      this.getGames(params)
        .then((result) => {
          
          this.games = result.data.result.data;
          this.total = result.data.result.total;
          this.lastPage = result.data.result.last_page;
          this.loading = false;
        })
        .finally(() => {});
    },
    changePage(val) {
      this.page = val;
      this.fetchGames();
    },
  },
};
</script>

<style lang="scss" scoped>
.games-container {
  .games {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
.fit-height {
  height: 80vh;
}
</style>
