<template>
  <div class="games-banner-slider">
    <ul class="slides">
      <li class="slide" v-for="(slide, i) in slides" :key="i">
        <transition :name="`${isNext ? 'right-slide' : 'left-slide'}`">
          <div class="slide-content" v-if="i === activeSlide">
            <img
              class="img"
              :src="require(`@/assets/images/running-card/${slide}`)"
              alt=""
            />
          </div>
        </transition>
      </li>
    </ul>
    <ul class="points">
      <li
        class="point"
        v-for="(slide, i) in slides"
        :key="i"
        @click="changePoint(i)"
        :class="{ active: i === activeSlide }"
      ></li>
    </ul>
  </div>
</template>

<script>
import { swipe } from "../../../mixins/swipe";
export default {
  mixins: [swipe],
  data() {
    return {
      slides: [
        "card1.jpg",
        "card2.jpg",
        "card3.jpg",
        "card4.jpg",
        "card5.jpg",
        "card5.jpg",
        "card5.jpg",
        "card5.jpg",
      ],
      activeSlide: 0,
      isNext: null,
    };
  },
  mounted() {
    // const activeSlide = document.querySelector('.games-banner-slider');
    // 
    // this.swipe(activeSlide, "right", this.swipeRight);
    // this.swipe(activeSlide, "left", this.swipeLeft);
  },
  methods: {
    changePoint(index) {
      if (index > this.activeSlide) {
        this.isNext = true;
      } else {
        this.isNext = false;
      }
      this.changeSlide(index);
    },
    changeSlide(index) {
      setTimeout(() => {
        this.activeSlide = index;
      }, 0.0001);
    },
  },
};
</script>

<style lang="scss" scoped>
.games-banner-slider {
  height: 40vh;
  padding: 0 3%;
  margin: 30px 0 40px 0;
  .slides {
    height: inherit;
    position: relative;
    .slide {
      width: 100%;
      height: inherit;
      position: absolute;
      .slide-content {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        .img {
          border-radius: 5px;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  .points {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    .point {
      width: 12px;
      height: 12px;
      border: 2px solid white;
      border-radius: 25px;
      margin: 0 4px;
      transition: all 0.5s ease;
      cursor: pointer;
      &:hover {
        transform: scale(1.1);
        background: grey;
      }
      &.active {
        background: white;
        width: 25px;
      }
    }
  }
}
.right-slide-enter-active,
.right-slide-leave-active {
  transition: all 0.6s;
}
.right-slide-leave {
  transform: translateX(0);
}
.right-slide-leave-to {
  transform: translateX(100%);
}
.right-slide-enter {
  transform: translateX(-100%);
}
.right-slide-enter-to {
  transform: translateX(0);
}

.left-slide-enter-active,
.left-slide-leave-active {
  transition: all 0.6s;
}
.left-slide-leave {
  transform: translateX(0);
}
.left-slide-leave-to {
  transform: translateX(-100%);
}
.left-slide-enter {
  transform: translateX(100%);
}
.left-slide-enter-to {
  transform: translateX(0);
}
</style>
